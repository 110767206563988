<template>
  <label :class="hasMargin ? 'mb-2' : 'mb-0'">
    <p v-if="description" class="mb-4 mt-0">
      {{ description }}
    </p>
    <div class="relative">
      <span
        class="transition-[top] text-md absolute left-4 text-gray-text pointer-events-none"
        :class="
          !localValue || localValue === 'null' ? 'top-4' : 'top-1 text-xs'
        "
      >
        {{ label }} {{ required ? "" : "(optional)" }}
      </span>
      <select
        :id="selectId"
        ref="selectElement"
        v-model="localValue"
        class="bg-gray-100 pt-4 pl-4 h-12 w-full font-sans text-md border-none rounded text-left min-w-13 pr-9 focus:shadow-focus focus:outline-none appearance-none disabled:opacity-35"
        :class="selectColor === 'white' ? 'bg-white' : 'bg-gray-100'"
        :required="required"
        :disabled="disabled"
        :invalid="error != null"
        :color="selectColor"
        :tabindex="selectTabindex"
        :size="selectSize"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      >
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :selected="option.value === localValue"
        >
          {{ option.label }}
        </option>
      </select>
      <SvgIcon
        icon="chevron-down"
        class="w-5 h-5 text-gray-shape absolute z-20 right-3 top-4 pointer-events-none"
      />
    </div>

    <span v-if="error" class="text-red-600 mt-1 text-sm">
      {{ error }}
    </span>
  </label>
</template>

<script lang="ts" setup>
  interface Props {
    label?: string
    description?: string
    modelValue?: string
    options: { label: string; value: string }[]
    disabled?: boolean
    required?: boolean
    hasMargin?: boolean
    selectId?: string
    selectColor?: "gray" | "white"
    selectTabindex?: string
    selectSize?: "medium" | "small"
    messageSpace?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    label: "",
    hasMargin: true,
    description: "",
    modelValue: "",
    disabled: false,
    required: false,
    selectId: "",
    selectColor: "gray",
    selectTabindex: "0",
    selectSize: "medium",
    messageSpace: true,
  })

  const selectElement = ref<HTMLSelectElement | null>(null)
  const error = ref<string | null>(null)
  const focused = ref(false)
  const emit = defineEmits(["update:modelValue", "keyup"])

  const onFocus = () => {
    focused.value = true
  }

  const onBlur = (e: Event) => {
    focused.value = false
    onInput(e)
  }

  const localValue = computed({
    get() {
      return props.modelValue !== null
        ? props.modelValue
        : props.options[0]?.value
    },
    set(value) {
      emit("update:modelValue", value)
    },
  })

  const validate = (e: Event) => {
    const value = (e.target as HTMLSelectElement).value
    if (!value && props.required) {
      error.value = `${props.label} is required`
      selectElement.value?.setCustomValidity(error.value)
    } else {
      error.value = null
      selectElement.value?.setCustomValidity("")
    }
  }

  const onInput = (e: Event) => {
    validate(e)
  }

  onMounted(() => {
    if (process.env.NODE_ENV === "development" && props.messageSpace) {
      console.warn(
        "FormSelect.vue: messageSpace is deprecated. Please use hasMargin instead.",
      )
    }
  })
</script>
